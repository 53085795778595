'use client';

import { type MutationFunction, type UseMutationOptions, useMutation } from '@tanstack/react-query';

type TUseServerAction = MutationFunction<unknown, unknown>;
type TUseServerActionOptions<T> = UseMutationOptions<unknown, Error, T>;

export function useServerAction<T>(action: TUseServerAction, options: TUseServerActionOptions<T>) {
  return useMutation({
    mutationFn: action,
    ...options,
  });
}
